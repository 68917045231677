import React from 'react';

import styles from "./SplashScreen.module.css"
import Logo from '../atoms/Logo';

interface SplashScreenProps {
    handleButtonClick: Function
}

const SplashScreen: React.FC<SplashScreenProps> = (props) => {

    return (
        <div className={styles.splashContainer}>
            <div className={styles.splashContent}>
                <div className={styles.splashTitle}>Il Road Assistant ti dà il benvenuto!</div>
                <Logo />
                <div className={styles.splashButton}>
                    <button onClick={() => props.handleButtonClick()}>Inizia la conversazione</button>
                </div>
            </div>
        </div>
    );
};

export default SplashScreen;
