import React from "react"
import Logo from "../Logo"

import styles from "./ConversationExamples.module.css"

interface ExampleProps {
    message: string
}

const Example: React.FC<ExampleProps> = (props) => {
    return (
        <div className={styles.example}>
            {props.message}
        </div>
    )
}

const Caption = () => {
    return (
        <div className={styles.caption}>
            Questi sono alcuni esempi di richieste che puoi farmi!
        </div>
    )
}

const ConversationExamples = () => {

    return (
        <div className={styles.mainContainer}>
            <Logo />
            <div className={styles.examplesContainer}>
                <Example message="Quanto pago di pedaggio per andare da Roma a Milano?" />
                <Example message="Qual'è la situazione del traffico tra Firenze e Bari?" />
                <Example message="Devo fare un viaggio da Roma a Bologna, qual'è la situazione meteo?" />
            </div>
            <Caption />
        </div>
    )
}

export default ConversationExamples;