// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SplashScreen_splashContainer__t3Ghs {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    font-family: "Montserrat";
}

.SplashScreen_splashContent__Dib\\+\\+ {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
}

.SplashScreen_splashTitle__wihPT {
    color: #0061a1;
    font-size: x-large;
    text-align: center;
    font-weight: bold;
}

.SplashScreen_splashButton__cc9DY{
    width: 75%;
}

.SplashScreen_splashButton__cc9DY button{
    width: 100%;
    height: 50px;
    font-size: 18px;
    background-color: #0061a1;
    color: #fff;
    border: none;
    border-radius: 15px;
    padding: 8px 16px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/SplashScreen/SplashScreen.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,SAAS;IACT,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".splashContainer {\n    height: 100%;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    margin: 0;\n    font-family: \"Montserrat\";\n}\n\n.splashContent {\n    flex-grow: 2;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    padding: 40px 20px;\n}\n\n.splashTitle {\n    color: #0061a1;\n    font-size: x-large;\n    text-align: center;\n    font-weight: bold;\n}\n\n.splashButton{\n    width: 75%;\n}\n\n.splashButton button{\n    width: 100%;\n    height: 50px;\n    font-size: 18px;\n    background-color: #0061a1;\n    color: #fff;\n    border: none;\n    border-radius: 15px;\n    padding: 8px 16px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"splashContainer": `SplashScreen_splashContainer__t3Ghs`,
	"splashContent": `SplashScreen_splashContent__Dib++`,
	"splashTitle": `SplashScreen_splashTitle__wihPT`,
	"splashButton": `SplashScreen_splashButton__cc9DY`
};
export default ___CSS_LOADER_EXPORT___;
