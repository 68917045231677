export const getCompletion = async (user_message: string, conversationStatus: string) => {
    const response = await fetch(process.env.REACT_APP_ENDPOINT + 'get_completion', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            message: user_message,
            conversation_status: conversationStatus,
        }),
    });
    return response;
}