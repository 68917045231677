// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Montserrat" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: "Montserrat" !important;
}

ul {
  padding-left: 18px !important;
}

/* styles.css */

#root {
  background-color: black;
}

.app-container {
  background-color: white;
  border: 1px solid black;
  max-width: 350px;
  height: 650px;
  margin: auto;
  padding: 0px;
  display: flex;
  flex-direction: column;
}

.app-header {
  height: 50px;
  background-color: #00619e;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,oCAAoC;EACpC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA,eAAe;;AAEf;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;EACvB,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Montserrat\" !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ninput {\n  font-family: \"Montserrat\" !important;\n}\n\nul {\n  padding-left: 18px !important;\n}\n\n/* styles.css */\n\n#root {\n  background-color: black;\n}\n\n.app-container {\n  background-color: white;\n  border: 1px solid black;\n  max-width: 350px;\n  height: 650px;\n  margin: auto;\n  padding: 0px;\n  display: flex;\n  flex-direction: column;\n}\n\n.app-header {\n  height: 50px;\n  background-color: #00619e;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
