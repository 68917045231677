import React, { Fragment, useState } from 'react';

import { ReactComponent as SendIcon } from "../../statics/send_icon.svg"
import { ReactComponent as CopyIcon } from "../../statics/copy_icon.svg"

import styles from "./Chatbot.module.css"
import ConversationExamples from '../atoms/ConversationExamples';
import { getCompletion } from '../../api/completionAPI';
import { Conversation } from './Conversation';
import Popup from 'reactjs-popup';

export interface Message {
    text: string;
    conversationStatus: string;
    isUser: boolean;
    is_finished: "none" | "complete" | "partial"
    is_awaiting_confirmation: boolean;
}

interface ConversationResponse {
    message: string;
    conversation_status: string;
    is_finished: "none" | "complete" | "partial";
    is_awaiting_confirmation: boolean;
}

const Chatbot: React.FC = () => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputValue, setInputValue] = useState<string>('Quanto mi costa di pedaggio per andare da Roma Sud a Milano? Trovo traffico?');
    const [isWaitingResponse, setIsWaitingResponse] = useState<boolean>(false);
    const [conversationStatus, setConversationStatus] = useState<string>("")

    const [automatiConfirmationGiven, setAutomatiConfirmationGiven] = useState<boolean>(false);

    const [isConversationFinished, setIsConversationFinished] = useState<"none" | "complete" | "partial">("none");

    const [isModalOpen, setIsModalOpen] = useState(false);

    const createErrorMessage = (message: string) => {
        const botMessageError: Message = {
            text: message,
            conversationStatus: conversationStatus,
            isUser: false,
            is_finished: "none",
            is_awaiting_confirmation: false
        };
        return botMessageError
    }

    const generateResponse = async (user_message: string, forcedConvStatus: string | null = null) => {
        try {
            const response = await getCompletion(user_message, forcedConvStatus ? forcedConvStatus : conversationStatus);

            if (response.ok) {
                const resp = await response.text();
                const data: ConversationResponse = JSON.parse(resp)
                const botMessage: Message = {
                    text: data.message,
                    conversationStatus: data.conversation_status,
                    is_finished: data.is_finished,
                    is_awaiting_confirmation: data.is_awaiting_confirmation,
                    isUser: false,
                };
                return botMessage;
            } else {
                return createErrorMessage("Couldn't communicate with server");
            }
        } catch (error) {
            return createErrorMessage("Couldn't communicate with server");
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleEnterSendMessage = async (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            handleSendMessage()
        }
    }

    const handleResetConversation = () => {
        setMessages([])
        setConversationStatus("")
        setInputValue("")
        setIsConversationFinished("none")
        setIsWaitingResponse(false)
        setAutomatiConfirmationGiven(false)
    }

    const handleSendMessage = async () => {
        if (inputValue.trim() === '') {
            return;
        }

        const newMessage: Message = {
            text: inputValue,
            conversationStatus: conversationStatus,
            isUser: true,
            is_finished: "none",
            is_awaiting_confirmation: false
        };

        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInputValue('');
        setIsWaitingResponse(true);

        await generateResponse(newMessage.text).then(async (message) => {
            setConversationStatus(message.conversationStatus);
            if (message.is_awaiting_confirmation && !automatiConfirmationGiven) {
                setMessages(prevMessages => [...prevMessages, message]);
                await generateResponse("ok", message.conversationStatus).then(finalMessage => {
                    setConversationStatus(finalMessage.conversationStatus);
                    setAutomatiConfirmationGiven(true);
                    if (finalMessage.is_finished !== "none") {
                        setIsConversationFinished(finalMessage.is_finished)

                        let messageText = "Nel caso avessi bisogno di informazioni per un altro viaggio, clicca su \"<i>Reset</i>\" e iniziamo una nuova conversazione!";
                        if (finalMessage.is_finished === 'complete') {
                            messageText = "Non posseggo ulteriori informazioni per il tuo viaggio. " + messageText;
                        }

                        const endMessage: Message = {
                            text: messageText,
                            isUser: false,
                            is_finished: finalMessage.is_finished,
                            is_awaiting_confirmation: false,
                            conversationStatus: conversationStatus
                        }
                        setMessages(prevMessages => [...prevMessages, finalMessage, endMessage]);
                    }
                })
                setIsWaitingResponse(false);
            }
            else {
                setIsWaitingResponse(false);

                if (message.is_finished !== "none") {
                    setIsConversationFinished(message.is_finished)

                    let messageText = "Nel caso avessi bisogno di informazioni per un altro viaggio, clicca su \"<i>Reset</i>\" e iniziamo una nuova conversazione!";
                    if (message.is_finished === 'complete') {
                        messageText = "Non posseggo ulteriori informazioni per il tuo viaggio. " + messageText;
                    }

                    const endMessage: Message = {
                        text: messageText,
                        isUser: false,
                        is_finished: message.is_finished,
                        is_awaiting_confirmation: false,
                        conversationStatus: conversationStatus
                    }
                    setMessages(prevMessages => [...prevMessages, message, endMessage]);
                }
                else {
                    setMessages(prevMessages => [...prevMessages, message]);
                }
            }
        });
    };

    const handleCopyMessage = () => {
        navigator.clipboard.writeText(JSON.stringify(messages))
        setIsModalOpen(true)
    }

    return (
        <div className={styles.chatbotContainer}>

            <Popup onClose={() => { setIsModalOpen(false) }} open={isModalOpen} closeOnDocumentClick position="center center">
                <div className={styles.modalContainer}>
                    <a href="#home" className={styles.modalCloseButton} onClick={() => { setIsModalOpen(false) }}>
                        &times;
                    </a>
                    <div className={styles.modalContent}>
                        Messaggi copiati negli Appunti!
                    </div>
                </div>
            </Popup>

            <div className={styles.chatbotMessages}>
                {messages.length === 0 ?
                    (<ConversationExamples />)
                    :
                    (<Conversation messages={messages} isWaitingResponse={isWaitingResponse} />)}
            </div>

            <div className={styles.chatbotInput}>
                {(isConversationFinished !== 'complete') && (
                    <Fragment>
                        <input
                            type="text"
                            placeholder="Inserisci il tuo messaggio..."
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={handleEnterSendMessage}
                            disabled={isWaitingResponse}
                        />
                        <button className={styles.sendButton} onClick={handleSendMessage} disabled={isWaitingResponse}>
                            <SendIcon width={20} height={20} />
                        </button>
                    </Fragment>
                )}

                <button
                    className={styles.sendButton}
                    onClick={handleCopyMessage}
                    disabled={isWaitingResponse}
                    style={{ marginLeft: 5, color: 'white' }}>
                    <CopyIcon width={20} height={20} color='white' fill='white' />
                </button>
                <button
                    className={styles.resetButton}
                    onClick={handleResetConversation}
                    style={{
                        width: isConversationFinished === "complete" ? "100%" : "60px",
                        marginLeft: "5px"
                    }}>
                    Reset
                </button>

            </div>
        </div>
    );
};

export default Chatbot;
