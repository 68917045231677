import React from 'react';
import stylesChatbot from "./../../Chatbot/Chatbot.module.css";
import styles from './WaitingMessage.module.css'

export const WaitingMessage: React.FC<React.RefAttributes<HTMLElement>> = () => {
    return (
        <div
            key={129847}
            className={stylesChatbot.botMessage}
        >
            <div className={styles.stage}>
                <div className={styles.dotFlashing}></div>
            </div>
        </div>
    );
};
