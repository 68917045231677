import React, { useState } from 'react';
import './App.css';
import Chatbot from './components/Chatbot';
import SplashScreen from './components/SplashScreen';

const App: React.FC = () => {

  const [splashScreen, setSplashScreen] = useState<boolean>(true);

  const handleButtonClick = () => {
    setSplashScreen(false);
  }

  return (
    <div className="app-container">
      {splashScreen
        ? (<SplashScreen handleButtonClick={handleButtonClick} />)
        : (<Chatbot />)}
    </div>
  );
}

export default App;
