import styles from "./Logo.module.css"

import logoImg from '../../../statics/logo.png'

const Logo = () => {

    return (
        <div className={styles.splashLogo}>
            <img alt="Logo Road Assistant" src={logoImg} />
        </div>
    )
}

export default Logo;