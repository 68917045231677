// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConversationExamples_mainContainer__vpJ2o {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: 13px;
    height: 100%;
}


.ConversationExamples_examplesContainer__Qqvfb {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 2;
    padding-top: 150px;
    padding-bottom: 10px;
}

.ConversationExamples_example__c6jnN {
    background-color: #f5f5f5;
    color: #838383;
    padding: 15px 5px;
    border-radius: 10px;
    width: 85%;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.ConversationExamples_caption__xTo-9 {
    color: #838383;
    font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/ConversationExamples/ConversationExamples.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,YAAY;AAChB;;;AAGA;IACI,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB","sourcesContent":[".mainContainer {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    text-align: center;\n    font-size: 13px;\n    height: 100%;\n}\n\n\n.examplesContainer {\n    margin-top: 0px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    flex-grow: 2;\n    padding-top: 150px;\n    padding-bottom: 10px;\n}\n\n.example {\n    background-color: #f5f5f5;\n    color: #838383;\n    padding: 15px 5px;\n    border-radius: 10px;\n    width: 85%;\n    height: 40px;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n}\n\n.caption {\n    color: #838383;\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `ConversationExamples_mainContainer__vpJ2o`,
	"examplesContainer": `ConversationExamples_examplesContainer__Qqvfb`,
	"example": `ConversationExamples_example__c6jnN`,
	"caption": `ConversationExamples_caption__xTo-9`
};
export default ___CSS_LOADER_EXPORT___;
