// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WaitingMessage_stage__cfiqq {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2px 0;
    margin: 0 -5%;
    overflow: hidden;
}

.WaitingMessage_dotFlashing__19UjI {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #0061a1;
    color: #0061a1;
    animation: WaitingMessage_dotFlashing__19UjI 1s infinite linear alternate;
    animation-delay: 0.5s;
}

.WaitingMessage_dotFlashing__19UjI::before,
.WaitingMessage_dotFlashing__19UjI::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.WaitingMessage_dotFlashing__19UjI::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #0061a1;
    color: #0061a1;
    animation: WaitingMessage_dotFlashing__19UjI 1s infinite alternate;
    animation-delay: 0s;
}

.WaitingMessage_dotFlashing__19UjI::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #0061a1;
    color: #0061a1;
    animation: WaitingMessage_dotFlashing__19UjI 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes WaitingMessage_dotFlashing__19UjI {
    0% {
        background-color: #0061a1;
    }

    50%,
    100% {
        background-color: #5ebeff;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/WaitingMessage/WaitingMessage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,yEAAmD;IACnD,qBAAqB;AACzB;;AAEA;;IAEI,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,MAAM;AACV;;AAEA;IACI,WAAW;IACX,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,kEAA4C;IAC5C,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,kEAA4C;IAC5C,mBAAmB;AACvB;;AAEA;IACI;QACI,yBAAyB;IAC7B;;IAEA;;QAEI,yBAAyB;IAC7B;AACJ","sourcesContent":[".stage {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n    padding: 2px 0;\n    margin: 0 -5%;\n    overflow: hidden;\n}\n\n.dotFlashing {\n    position: relative;\n    width: 10px;\n    height: 10px;\n    border-radius: 5px;\n    background-color: #0061a1;\n    color: #0061a1;\n    animation: dotFlashing 1s infinite linear alternate;\n    animation-delay: 0.5s;\n}\n\n.dotFlashing::before,\n.dotFlashing::after {\n    content: \"\";\n    display: inline-block;\n    position: absolute;\n    top: 0;\n}\n\n.dotFlashing::before {\n    left: -15px;\n    width: 10px;\n    height: 10px;\n    border-radius: 5px;\n    background-color: #0061a1;\n    color: #0061a1;\n    animation: dotFlashing 1s infinite alternate;\n    animation-delay: 0s;\n}\n\n.dotFlashing::after {\n    left: 15px;\n    width: 10px;\n    height: 10px;\n    border-radius: 5px;\n    background-color: #0061a1;\n    color: #0061a1;\n    animation: dotFlashing 1s infinite alternate;\n    animation-delay: 1s;\n}\n\n@keyframes dotFlashing {\n    0% {\n        background-color: #0061a1;\n    }\n\n    50%,\n    100% {\n        background-color: #5ebeff;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stage": `WaitingMessage_stage__cfiqq`,
	"dotFlashing": `WaitingMessage_dotFlashing__19UjI`
};
export default ___CSS_LOADER_EXPORT___;
