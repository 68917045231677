// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo_splashLogo__5UMXa {
    width: 150px;
    height: 150px;
}

.Logo_splashLogo__5UMXa img {
    width: 150px;
    height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/Logo/Logo.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".splashLogo {\n    width: 150px;\n    height: 150px;\n}\n\n.splashLogo img {\n    width: 150px;\n    height: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"splashLogo": `Logo_splashLogo__5UMXa`
};
export default ___CSS_LOADER_EXPORT___;
